import { HeaderOptionsType } from 'common/globalTypes/types/common';
import isServerSide from 'utils/helpers/isServerSide';
import langConvertByCountryCode from 'utils/helpers/langConvertByCountryCode';
import getLanguageByUrl from 'utils/helpers/getLanguageByUrl';
import { HeadersEnum } from 'common/globalTypes/enums/http';
import { BEARER, cookieName } from 'common/constants/authorization';
import CookieHandler from './cookieHandler';

const buildHeaders = (options: HeaderOptionsType): HeadersInit => {
  if (isServerSide) {
    //detect language by url and set into header
    const detectedLang = getLanguageByUrl();
    options[HeadersEnum.xCurrentLocale] =
      langConvertByCountryCode(detectedLang);
    const authorizationToken = CookieHandler.getCookie(cookieName);

    if (authorizationToken) {
      options[HeadersEnum.authorization] = `${BEARER} ${CookieHandler.getCookie(
        cookieName
      )}`;
    }
  }

  return options;
};

export default buildHeaders;
