import buildHeaders from 'utils/helpers/buildHeaders';
import fetcherErrorHandler from 'utils/helpers/fetcherErrorHandler';
import { defaultTypeOfCache } from 'common/constants/common';
import { IRequestParameters } from './types';
import {
  BackendResponseModelTypes,
  ContentTypesEnum,
  HttpMethodsEnum
} from 'common/globalTypes/enums/http';

class BaseFetcher {
  private readonly domain: string;

  constructor(domain: string) {
    this.domain = domain;
  }

  async request<T>({
    url,
    method,
    data = null,
    hideErrorMessage,
    headerOptions = {},
    cache = defaultTypeOfCache,
    next,
    signal,
    responseModel = BackendResponseModelTypes.WithData
  }: IRequestParameters): Promise<T> {
    const headers = buildHeaders(headerOptions);
    const body = data ? JSON.stringify(data) : data;

    const response = await this.uniqFetcher(
      url,
      method,
      headers,
      cache,
      body,
      signal,
      hideErrorMessage,
      next
    );

    const result =
      method !== HttpMethodsEnum.DELETE ? await response.json() : {};

    switch (responseModel) {
      case BackendResponseModelTypes.WithNoDataProperty:
        return result;

      default:
        return result.data;
    }
  }

  async uniqFetcher(
    url: string,
    method: string,
    headers: HeadersInit,
    cache: RequestCache,
    body: any,
    signal?: AbortSignal,
    hideErrorMessage?: boolean,
    next?: { revalidate: number }
  ): Promise<Response> {
    const fetchOptions: RequestInit = {
      method,
      headers: {
        accept: ContentTypesEnum.applicationJson,
        ...headers
      },
      body,
      signal
    };

    if (!next) {
      fetchOptions.cache = cache;
    }

    if (next) {
      fetchOptions.next = next;
    }

    const response = await fetch(`${this.domain}/${url}`, fetchOptions);

    return fetcherErrorHandler(response, hideErrorMessage);
  }
}

export default BaseFetcher;
