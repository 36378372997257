import { EnvConfig } from 'configs/env';

export const target = 'app-store';

export const unsetCookieRouteApi = '/api/unset-cookie';
export const appHealthCheck = '/api/status';
export const deleteCookieAuthorizationQueryParam = 'deleteCookie';

export const getNonceAndSetTokenApi = '/api/callback';
export const robotTxtFilePath = '/robots.txt';
export const siteMapXmlFilePath = '/sitemap.xml';
export const nonce = 'nonce';

export const BEARER = 'Bearer';

export const returnUrlQueryParam = 'returnUrl';
export const targetName = 'target';

export const notValidAuthorizationTokenCallbackApi = `${EnvConfig.appStoreClient}${unsetCookieRouteApi}?${deleteCookieAuthorizationQueryParam}=true`;

export const callbackUrlToSso = `${EnvConfig.accountsBackend}/callback?target=${target}`;

export const cookieName = 'token';
export const unauthorizedStatus = 401;
